
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import { MarkerType, Link } from "@/store/mapService";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AddMapModal",
  data() {
    return {
      title: "" as string,
      icon: "" as string,
      pin: "" as string,
      desc: "" as string,
      link: {
        title: "",
        url: ""
      } as Link,
      markerType: MarkerType.date as MarkerType,
      showIcons: true as boolean,
      minZoom: 0 as number,
      gestureHandling: "auto" as string,
      dialog: false as boolean
    };
  },
  computed: {
    ...mapGetters(["mapTitles"]),
    maps(): string {
      return this.mapTitles.join();
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.clear();
      }
    }
  },
  created() {
    const dictionary = {
      en: {
        messages: {
          excluded() {
            return "A map with that name already exists";
          }
        }
      }
    };
    this.$validator.localize(dictionary);
  },
  methods: {
    async addMap() {
      const allValid = await this.$validator.validateAll();

      if (allValid) {
        await this.$store.dispatch("addNewMap", [
          this.title,
          this.icon,
          this.pin,
          this.desc,
          this.link,
          this.showIcons,
          this.markerType,
          this.minZoom,
          this.gestureHandling
        ]);
        this.dialog = false;
        this.$router.push(`/maps/${this.title}`);
      }
    },
    clear() {
      this.title = "";
      this.pin = "";
      this.icon = "";
      this.desc = "";
      this.link = {
        title: "",
        url: ""
      };
      this.markerType = this.getMarkerTypeDate();
      this.showIcons = true;
      this.minZoom = 0;
      this.gestureHandling = "auto";
      this.$validator.reset();
    },
    openDialog() {
      this.dialog = true;
    },
    getMarkerTypeDate() {
      return MarkerType.date;
    },
    getMarkerTypeNumber() {
      return MarkerType.number;
    }
  }
});
